// LoaderContext.js
import React, { createContext, useState, useContext } from 'react';

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
    const [apiLoader, setApiLoader] = useState(false);

    return (
        <LoaderContext.Provider value={{ apiLoader, setApiLoader }}>
            {children}
        </LoaderContext.Provider>
    );
};

export const useLoader = () => {
    return useContext(LoaderContext);
};
