import './cart-widget.css';
import journal from '../assets/images/journal.png';

function CartWidget({onClose, counterValue}) {

    return (
        <div className='widget-container'>
            <div className='widget'>
                <span className='widget-close close calculation' onClick={onClose}>&#10005;</span>
                <img className='widget-img' src={journal} />
                <div className='widget-content'>
                    <span className='widget-text'>
                        Enslaved to<br/> this journal
                    </span>
                    <button className='btn-style add-cart sub-cart' onClick={counterValue}>Add to cart</button>
                </div>
            </div>
        </div>
    )
}

export default CartWidget;