import React, { useEffect, useState } from 'react';
import gsap from 'gsap';
import './newsletter.css';
import Input from './input/input';
import underline from '../assets/images/Underline.svg';
import wave from '../assets/images/Wave.svg';
import tick from '../assets/images/Tick.svg';

function Newsletter() {
    const [emailSent, setEmailSent] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const wave = document.getElementById('wave');

        // Create the animation
        const animation = gsap.to(wave, {
            rotation: 30, // Tilt 10 degrees to the right
            repeat: -1, // Repeat indefinitely
            yoyo: true, // Animate back to the original position
            ease: "power1.inOut", // Smooth easing
            duration: 1 // Duration of one full cycle
        });

        // Cleanup function to stop the animation when the component unmounts
        return () => {
            animation.kill(); // Stop the animation
        };
    }, []); // Empty dependency array ensures this runs once


    const handleSendEmail = async (email) => {
        try {
            const response = await fetch('https://connect.enslavetothisjournal.com/newsletter/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (!response.ok) {
                if (response.status === 400) { // Assuming 409 is the status code for email already exists
                    setError('Email already exists');
                } else {
                    setError('Something went wrong, please try again.');
                }
                return;
            }

            const result = await response.json();
            setEmailSent(true); // Set emailSent to true on successful email submission
        } catch (error) {
            console.error('Error:', error);
            setError('Something went wrong, please try again.');
        }
    };

    const clearError = () => {
        setError('');
    };

    return (
        <div className='news-container'>
            <div className='news-header'>
                <span className='news-txt'>
                    Drop your email <img className='wave' id="wave" src={wave} alt="wave" /> and join our community of dreamers and daydream believers
                </span>
                <img src={underline} alt="underline" />
            </div>
            {!emailSent ? (
                <div className='email-input'>
                    <Input onSendEmail={handleSendEmail} error={error} clearError={clearError} />
                </div>
            ) : (
                <div className='email-sent'>
                    <img src={tick} alt="tick" />
                    <span>Email Sent</span>
                </div>
            )}
            {!emailSent && <span className='mail-pitch'>we'll never spam you, Promise</span>}
        </div>
    );
}

export default Newsletter;
