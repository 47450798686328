import React from 'react';
import './loader.css';
import loaderGif from '../assets/images/Journal logo without bg.gif';

const Loader = () => {
  return (
    <div className='main-loader-container'>
      <div className="loader">
        <img src={loaderGif} alt="Loading..." />
      </div>
    </div>
  );
};

export default Loader;
