import React from 'react';
import notFountVector from '../assets/images/Spaceship (3).svg'
import './NotFound.css';
import { useNavigate } from 'react-router-dom';
import NotHeader from './not-header/NotHeader';

const NotFound = () => {

    const navigate = useNavigate();

    function backToHome() {
        navigate('/');
    }

    return (
        <div>
        <NotHeader/>
        <div className='not-found'>
            <img className='not-found-space' src={notFountVector} />
            <div className='not-found-txt'>Oops, Lost in the pages?</div>
            <div className='not-found-subtext'>Let's get you back home to find the perfect journal for you.
            </div>
            <button className='btn-style add-cart back-home travel-home' onClick={backToHome}>Back to Home</button>
        </div>
        </div>
    );
};

export default NotFound;
