import './return.css';
import arrow from '../assets/images/right-arrow.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import wallet from '../assets/images/Wallet.svg';

function Return({onCloseReturn}) {
    const navigate = useNavigate();

    useEffect(() => {
        window.scroll(0,0)
    }, [])

    function onCloseReturn() {
        navigate('/')
    }
    return (
        <div className="return-container">
            <div className='terms-header'>
                <span className='go_back' onClick={onCloseReturn}><img className="arrow2 back_option" src={arrow} alt="arrow1" /></span>
                <span className='return-policy-text'>Return Policy</span>
                <span></span>
            </div>

            <div className='return-content'>
                <img className='wallet' src={wallet}/>
                <h2>WHAT DO WE DO WITH YOUR INFORMATION?</h2>
                            <p className='return_text'>We want to ensure your satisfaction with every purchase made from our online store. If you receive a physically damaged product, please contact our customer service team with photographic evidence within 7 days of receiving the item. Upon verification, we will be happy to send you a new product to replace the damaged item. Please note that we do not offer refunds or returns for any reason other than receiving a physically damaged product. We appreciate your understanding in this matter.</p>
                            <p className='return_text'>If you have any questions or concerns about our refund policy, please feel free to contact our customer service team for assistance.</p>
            </div>
        </div>
    )
}

export default Return;
