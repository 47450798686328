import './checkout.css';
import paytm from '../assets/images/paytm.webp';
import phonepe from '../assets/images/phonepe.png';
import gpay from '../assets/images/gpay.svg';

function Checkout({ processCheckout, disabled }) {
    return (
        <button
            className={`checkout-style ${disabled ? 'disabled' : ''}`}
            onClick={processCheckout}
            disabled={disabled}
        >
            Checkout
            <div className='payment-options'>
                <img className="pay-option overlap1" src={paytm} alt="Paytm" />
                <img className="pay-option overlap2" src={phonepe} alt="PhonePe" />
                <img className="pay-option" src={gpay} alt="GPay" />
            </div>
            <span className='next'>&#8250;</span>
        </button>
    );
}

export default Checkout;
