import './NotHeader.css';
import cart from '../../assets/images/Shopping cart white.svg';
import logo from '../../assets/images/Logo white.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import home from '../../assets/images/Home white.svg';

function NotHeader() {
    const navigate = useNavigate();
    const [cartValue, setCartValue] = useState(0);

    const openCart = () => {
        navigate('/cart');
    };

    const navigateHome = () => {
        navigate('/');
    };

    return (
        <div className="header not-header">
            <img className='logo' src={logo} alt="Logo" />
            <span className="home" onClick={navigateHome}><img src={home} alt="Home" /></span>
            <span 
                className={`cart ${cartValue > 0 ? 'show-counter' : ''}`} 
                onClick={openCart} 
                data-value={cartValue}
            >
                <img className='cart-img' src={cart} alt="Cart" />
            </span>
        </div>
    )
}

export default NotHeader;