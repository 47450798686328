// Loader.js
import React from 'react';
import Lottie from 'react-lottie';
import loaderAnimation from '../assets/loader/loader.json'; // Replace with the path to your JSON file
import { useLoader } from '../loader-context';

const ApiLoader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loaderAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const { apiLoader } = useLoader();

    if (!apiLoader) return;

    return (
        <div className="loader-container">
            <Lottie options={defaultOptions} height={100} width={100} />
        </div>
    );
};

export default ApiLoader;
