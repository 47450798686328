import './terms.css';
import arrow from '../assets/images/right-arrow.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import note from '../assets/images/Note.svg';
import chat from '../assets/images/chat.svg'

function Terms() {
    const navigate = useNavigate();

    useEffect(() => {
        window.scroll(0, 0);
        const setPositions = () => {
            const noteElement = document.querySelector('.note');
            const chatElement = document.querySelector('.chat');
            const documentHeight = document.documentElement.scrollHeight;

            if (noteElement) {
                noteElement.style.top = `${0.18 * documentHeight}px`; // 30% of page height
            }

            if (chatElement) {
                chatElement.style.top = `${0.4 * documentHeight}px`; // 60% of page height
            }
        };

        setPositions();
        window.addEventListener('resize', setPositions);

        return () => {
            window.removeEventListener('resize', setPositions);
        };
    }, []);

    useEffect(() => {
        window.scroll(0,0)
    }, [])

    const closeTerms = () => {
        navigate('/');
    }
    return (
        <div className='terms-container'>
            <div className='terms-header'>
                <span className='go_back' onClick={closeTerms}><img className="arrow2 back_option" src={arrow} alt="arrow1" /></span>
                <span className='terms-and-conditions'>Terms and Conditions</span>
                <span></span>
            </div>
                    <div className='terms-content'>
                        <img className='note' src={note}/>
                        <img className='chat' src={chat}/>
                        <div className='test-txt'>
                        <h2>Terms of Service</h2>
                            <p className='main_terms_content'><strong>Effective Date: July 1, 2024</strong></p>
                            <p className='main_terms_content'>Welcome to Enslave to This Journal's online store! These terms of use outline the rules and regulations for the use of our website.</p>
                            <p className='main_terms_content'>By accessing this website, we assume you accept these terms of use in full. Do not continue to use this website if you do not accept all of the terms of use stated on this page.</p>
                            <p className='main_terms_content'>The following terminology applies to these terms of use: "Client", "You", and "Your" refer to you, the person accessing this website and accepting Enslave to This Journal's terms of use. "Enslave to This Journal", "We", "Us", and "Our" refer to Enslave to This Journal. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client most appropriately, whether by formal meetings of a fixed duration or any other means, for the express purpose of meeting the Client’s needs in respect of the provision of Enslave to This Journal’s stated services/products, by and subject to, prevailing law of. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they are taken as interchangeable and therefore as referring to the same.</p>

                            <div className="section">
                                <h2>Section 1 - Cookies</h2>
                                <p className='main_terms_content'>We employ the use of cookies. By using our website, you consent to the use of cookies by our privacy policy.</p>
                            </div>

                            <div className="section">
                                <h2>Section 2 - License</h2>
                                <p className='main_terms_content'>Unless otherwise stated, Enslave to This Journal and/or our licensors own the intellectual property rights for all material on our website. All intellectual property rights are reserved. You may view and/or print pages from the website for your own personal use subject to restrictions set in these terms of use.</p>
                                <p className='main_terms_content'>You must not:</p>
                                <ul>
                                    <li className='main_terms_content'>Republish material from this website</li>
                                    <li className='main_terms_content'>Sell, rent or sublicense material from the website</li>
                                    <li className='main_terms_content'>Reproduce, duplicate or copy material from the website</li>
                                    <li className='main_terms_content'>Redistribute content from our website</li>
                                </ul>
                                <p className='main_terms_content'>This Agreement shall begin on the date hereof.</p>
                            </div>

                            <div className="section">
                                <h2>Section 3 - Hyperlinking to our Content</h2>
                                <p className='main_terms_content'>The following organizations may link to our website without prior written approval:</p>
                                <ul>
                                    <li className='main_terms_content'>Government agencies</li>
                                    <li className='main_terms_content'>Search engines</li>
                                    <li className='main_terms_content'>News organizations</li>
                                    <li className='main_terms_content'>Online directory distributors when they list us in the directory may link to our website in the same manner as they hyperlink to the websites of other listed businesses; and</li>
                                    <li className='main_terms_content'>Systemwide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our website.</li>
                                </ul>
                                <p className='main_terms_content'>These organizations may link to our home page, to publications or to other website information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>
                                <p className='main_terms_content'>Approved organizations may hyperlink to our website as follows:</p>
                                <ul>
                                    <li className='main_terms_content'>By use of our corporate name; or</li>
                                    <li className='main_terms_content'>By use of the uniform resource locator being linked to; or</li>
                                    <li className='main_terms_content'>By use of any other description of our website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
                                </ul>
                                <p className='main_terms_content'>No use of Enslave to This Journal's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 4 - Iframes</h2>
                                <p className='main_terms_content'>Without prior approval and express written permission, you may not create frames around our web pages or use other techniques that alter the visual presentation or appearance of our website.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 5 - Reservation of Rights</h2>
                                <p className='main_terms_content'>Enslave to This Journal reserves the right at any time and in its sole discretion to request that you remove all links or any particular link to our website. You agree to remove all links to our website upon such request immediately. Enslave to This Journal also reserves the right to amend these terms of use and its linking policy at any time. By continuing to link to our website, you agree to be bound to and abide by these linking terms of use.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 6 - Removal of links from our website</h2>
                                <p className='main_terms_content'>If you find any link on our website or any linked website objectionable for any reason, you may contact us using the following contact information: <a href="mailto:hi@enslavetothisjournal.com">hi@enslavetothisjournal.com</a>. We will consider requests to remove links but are under no obligation to do so or to respond directly to you.</p>
                                <p className='main_terms_content'>Whilst we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 7 - Content Liability</h2>
                                <p className='main_terms_content'>We shall have no responsibility or liability for any content appearing on your website. You agree to indemnify and defend Enslave to This Journal against all claims arising out of or based upon your website. No link(s) may appear on any page on your website or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 8 - THIRD-PARTY LINKS</h2>
                                <p className='main_terms_content'>Certain content, products and services available via our Service may include materials from third-parties. Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 9 - Disclaimer</h2>
                                <p className='main_terms_content'>To the maximum extent permitted by applicable law, Enslave to This Journal excludes all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
                                <ul>
                                    <li className='main_terms_content'>limit or exclude Enslave to This Journal's or your liability for death or personal injury resulting from negligence;</li>
                                    <li className='main_terms_content'>limit or exclude Enslave to This Journal's or your liability for fraud or fraudulent misrepresentation;</li>
                                    <li className='main_terms_content'>limit any of Enslave to This Journal's or your liabilities in any way that is not permitted under applicable law; or</li>
                                    <li className='main_terms_content'>exclude any of Enslave to This Journal's or your liabilities that may not be excluded under applicable law.</li>
                                </ul>
                                <p className='main_terms_content'>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty. As long as the website and its information and services are provided free of charge, Enslave to This Journal will not be liable for any loss or damage of any nature.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 10 - Indemnification</h2>
                                <p className='main_terms_content'>You agree to indemnify, defend and hold harmless Enslave to This Journal and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Use or the documents they incorporate by reference or your violation of any law or the rights of a third-party.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 11 - Severability</h2>
                                <p className='main_terms_content'>In the event that any provision of these Terms of Use is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Use, such determination shall not affect the validity and enforceability of any other remaining provisions.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 12 - Termination</h2>
                                <p className='main_terms_content'>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.</p>
                                <p className='main_terms_content'>These Terms of Use are effective unless and until terminated by either you or us. You may terminate these Terms of Use at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Use, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 13 - Entire Agreement</h2>
                                <p className='main_terms_content'>The failure of us to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision.</p>
                                <p className='main_terms_content'>These Terms of Use and any policies or operating rules posted by us on this site or in respect to the Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Use).</p>
                                <p className='main_terms_content'>Any ambiguities in the interpretation of these Terms of Use shall not be construed against the drafting party.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 14 - Changes to Terms of Service</h2>
                                <p className='main_terms_content'>You can review the most current version of the Terms of Service at any time at this page.</p>
                                <p className='main_terms_content'>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                            </div>

                            <div className="section">
                                <h2>SECTION 15 - Contact Information</h2>
                                <p className='main_terms_content'>Questions about the Terms of Service should be sent to us at <a href="mailto:hi@enslavetothisjournal.com">hi@enslavetothisjournal.com</a>.</p>
                            </div>

                            <div className='section'>
                                <h2>Operation Address - </h2>
                                <p className='main_terms_content'>
                                Ground floor, 123, Koserian Bawdi Road, UP Mahal Oel,
                                Kosrian Sector, Bilaspur,<br/> Bilaspur, Himachal Pradesh,
                                174001

                                </p>
                            </div>
                        </div>
                    </div>
        </div>
    )
}

export default Terms;