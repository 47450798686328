import './address-form.css';
import { gsap } from "gsap";
import { useEffect, useState } from 'react';
import journal from '../assets/images/journal.png';
import chevron from '../assets/images/chevron.svg';
import Checkout from '../checkout/Checkout';
import { useGSAP } from '@gsap/react';
import { useNavigate } from 'react-router-dom';
import useAxiosInstance from '../axiosInstance';
import SavedForm from './saved-address/saved-address';
import Confirmation from './confirmation/confirmation';
import CouponComponent from './coupon/Coupon';

gsap.registerPlugin(useGSAP);

function AddressForm({ counterValue, onOrderCreationId }) {
    const axios = useAxiosInstance();

    const navigate = useNavigate();

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        address: '',
        apartment: '',
        country: '',
        state: '',
        city: '',
        pincode: ''
    });

    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [showSavedForm, setShowSavedForm] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [discount, setDiscount] = useState(0);


    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const response = await axios.get('https://countriesnow.space/api/v0.1/countries/currency');
                const countriesData = response.data.data.map(country => country);
                const sortedCountries = sortAlphabetically(countriesData);
                setCountries(sortedCountries);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        fetchCountries();
    }, []);

    useEffect(() => {
        validate();
    }, [formData]);

    const [errors, setErrors] = useState({});
    const [collapsed, setCollapsed] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        if (name === 'country') {
            fetchStates(value);
        }
    };

    const fetchStates = async (country) => {
        try {
            const response = await axios.post('https://countriesnow.space/api/v0.1/countries/states', { country });
            const sortedStates = sortAlphabetically(response.data.data.states);
            setStates(sortedStates);
        } catch (error) {
            console.error("Error fetching states:", error);
        }
    };
  
    const validate = () => {
        let formErrors = {};
        Object.keys(formData).forEach(key => {
            if (!formData[key]) {
                formErrors[key] = 'This field is required';
            }
        });
  
        if (!/^\d{5,6}$/.test(formData.pincode)) {
            formErrors.pincode = 'Invalid pincode';
        }
  
        setErrors(formErrors);
        setIsFormValid(Object.keys(formErrors).length === 0);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        validate();
        if (Object.keys(errors).length === 0) {
            setShowSavedForm(true); // Show SavedForm and hide the form
        }
    };

    const handleEditAddress = (savedData) => {
        setFormData(savedData);
        setShowSavedForm(false);
    };
    
    const onCloseAddress = () => {
        if (Object.keys(formData).some(key => formData[key])) {
            setShowConfirmation(true);
        } else {
            navigate('/cart');
        }
    };

    const handleConfirmClose = (confirm) => {
        setShowConfirmation(false);
        if (confirm) {
            navigate('/cart');
        }
    };

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
        if (collapsed) {
            gsap.to(".journal-main", { duration: 0.5, opacity: 1, height: "auto", display: "flex" });
        } else {
            gsap.to(".journal-main", { duration: 0.5, opacity: 0, height: 0, display: "none" });
        }
    };

    const sortAlphabetically = (array) => {
        return array.sort((a, b) => a.name.localeCompare(b.name));
    };

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
    
        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }
    
        const amount = counterValue * 1500 - discount;
        const currency = countries.find((country) => country.name === formData.country).currency;
        const orderDate = new Date(); // Current date and time
    
        try {
            const result = await axios.post("https://connect.enslavetothisjournal.com/payment/orders", { amount, currency });
    
            if (!result) {
                alert("Server error. Are you online?");
                return;
            }
    
            const { amount: razorpayAmount, id: order_id, currency: razorpayCurrency } = result.data;
            const options = {
                key: 'rzp_live_jtrJeNfjNwVuI4', // Enter the Key ID generated from the Dashboard
                amount: razorpayAmount.toString(),
                currency: razorpayCurrency.toString(),
                name: "Enslave to this journal",
                description: "Transaction",
                order_id: order_id,
                handler: async function (response) {
                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };

                    try {
                        await axios.post("https://connect.enslavetothisjournal.com/payment/success", data);
    
                        // Save address and additional order details
                        navigate('/thank-you');
                        onOrderCreationId(order_id);
                    } catch (error) {
                        console.error("Error processing payment success:", error);
                        alert("Payment processing failed. Please try again.");
                    }

                    try {
                        await axios.post("https://connect.enslavetothisjournal.com/save-order", {
                            addressData: formData,
                            orderAmount: razorpayAmount,
                            numberOfOrders: counterValue,
                            orderId: order_id,
                            orderDate: orderDate
                        });
                    } catch (error) {
                        console.log('Failed to save address');
                    }
                },
                prefill: {
                    name: formData.firstName + ' ' + formData.lastName,
                    contact: formData.mobile,
                    email: formData.email
                },
                notes: {
                    address: formData.address,
                },
                theme: {
                    color: "#FEFAE7",
                },
            };
    
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (error) {
            console.error("Error creating order:", error);
            alert("Failed to create order. Please try again.");
        }
    }

    const handleCouponApply = (discountValue) => {
        console.log(`Coupon applied: ${discountValue}`);
        setDiscount(discountValue); // Apply the discount
    };

    return (
        <div className="address-container">
            <div className='address-header'>
                <div></div>
                <div className='address-header'>
                    Add Address
                </div>
                <button className="close calculation" onClick={onCloseAddress}>&#10005;</button>
            </div>

            <div className='address-content'>
                <div className='address-details'>
                {showSavedForm ? (
                        <SavedForm formData={formData} onEdit={handleEditAddress}/> // Pass the formData to SavedForm
                    ) : (
                    <form onSubmit={handleSubmit} className="address-form">
                        <div className='address-row address-coupon-mobile'>
                            <CouponComponent onApplyCoupon={handleCouponApply} />
                        </div>
                        <div className="form-group">
                            <input className='form-input' name="firstName" placeholder='First Name' value={formData.firstName} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <input className='form-input' name="lastName" placeholder='Last Name' value={formData.lastName} onChange={handleChange} />
                        </div>
                        <div className="form-group full-width">
                            <input className='form-input' name="address" placeholder='Address' value={formData.address} onChange={handleChange} />
                        </div>
                        <div className="form-group full-width">
                            <input className='form-input' name="apartment" placeholder='Apartment, suite, etc' value={formData.apartment} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <input className='form-input' name="mobile" placeholder='Mobile No.' value={formData.mobile} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <input className='form-input' name="email" placeholder='Email Id.' value={formData.email} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <select className='selector' name="country" value={formData.country} onChange={handleChange}>
                                <option value="">Select Country</option>
                                {countries.map(country => (
                                    <option key={country.name} value={country.name}>{country.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select 
                                className='selector' 
                                name="state" 
                                value={formData.state} 
                                onChange={handleChange}
                                disabled={!formData.country} // Disable if no country is selected
                            >
                                <option value="">Select State</option>
                                {states.map(state => (
                                    <option key={state.name} value={state.name}>{state.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="form-group">
                            <input className='form-input' name="city" placeholder='City' value={formData.city} onChange={handleChange} />
                        </div>
                        <div className="form-group">
                            <input className='form-input' name="pincode" placeholder='Pincode' value={formData.pincode} onChange={handleChange} />
                        </div>
                        <button type="submit" className="save-button full-width">Save Address</button>
                    </form>)
                }
                </div>

                <div className='final-details'>
                    <div className='journal-wrapper address-wrapper'>
                        <div className={`collapse ${collapsed ? 'expanded' : ''}`} onClick={toggleCollapse}>
                            <div className='left-collapse'>
                                <span>Summary</span>
                                <img src={chevron} alt="Chevron" className='chevron' />
                            </div>
                            
                            <div className='right-collapse final-total'>Rs. {counterValue*1500 - discount}.00</div>
                        </div>
                        <div className='journal-main'>
                            <div className='tag-line'>
                                <span className='address-journal-text'>Enslaved to this journal</span>
                                <img className='final-image' src={journal} alt="Journal"/>
                            </div>
                            <div className='journal-pricing address-pricing'>
                                <div className='address-quantity'>
                                    <div className='wrapper price'>
                                        <span className='label address-label'>
                                            Price
                                        </span>
                                        <span className='value address-label'>
                                            Rs. {counterValue*1500}.00
                                        </span>
                                    </div>
                                    <div className='wrapper'>
                                        <span className='label address-label'>
                                            Quantity
                                        </span>
                                        <span className='value address-label'>
                                            {counterValue} qty
                                        </span>
                                    </div>
                                </div>
                                <div className='total-calculation'>
                                    <div className='address-row'>
                                        <span className='name address-label'>Subtotal</span>
                                        <span className='detail address-label'>Rs. {counterValue*1500}.00</span>
                                    </div>
                                    {/* <div className='address-row'>
                                        <span className='name address-label'>Shipping</span>
                                        <span className='detail address-label'>Rs. 49.00</span>
                                    </div> */}
                                    
                                    {discount > 0 && <div className='address-row'>
                                        <span className='special-name address-label'>Coupon Code</span>
                                        <span className='detail address-label'> {`(-)`} Rs. {discount}.00</span>
                                    </div>}

                                    <div className='address-row'>
                                        <span className='name final-total'>Total</span>
                                        <span className='detail final-total'>Rs. {counterValue*1500 - discount}.00</span>
                                    </div>
                                    <div className='address-row address-coupon'>
                                        <CouponComponent onApplyCoupon={handleCouponApply} />
                                    </div>
                                    <div className='checkout-btn address-checkout'>
                                        <Checkout disabled={!isFormValid} processCheckout={displayRazorpay}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showSavedForm && <div className='checkout-btn address-checkout-mobile'>
                    <Checkout disabled={!isFormValid} processCheckout={displayRazorpay}/>
                </div>}

            </div>

            {showConfirmation && <Confirmation handleConfirmClose={handleConfirmClose}/>}
            {showConfirmation && <div className='confirm-overlay'></div>}
        </div>
    );
}

export default AddressForm;
