import './confirmation.css';
import panda from '../../assets/images/Panda.svg';

function Confirmation({handleConfirmClose}) {
    const handleNo = () => {
        handleConfirmClose(false);
    }

    const handleYes = () =>{
        handleConfirmClose(true);
    }

    return (
        <div className='confirmation-container'>
            <span className='confirm-ques'>Are you sure you don’t want to proceed with the order?</span>
            <div className='confirm-btns'>
                <button className="confirm-style" onClick={handleNo}>No</button>
                <button className="confirm-style" onClick={handleYes}>Yes</button>
            </div>
            <img className='panda' src={panda} />
        </div>
    )
}

export default Confirmation;