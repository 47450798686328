import { useState } from 'react';
import './thankyou.css';
import insta from '../assets/images/Instagram.svg';
import thread from '../assets/images/Email-contact.svg';
import bulb from '../assets/images/Blub.svg';
import { useNavigate } from 'react-router-dom';

function Thankyou({ orderCreationId }) {

    const navigate = useNavigate();

    function backToHome() {
        navigate('/');
    }

  const [orderId, setOrderId] = useState(orderCreationId);
    return (
        <div className='thanks-container'>
            <div className='thanks'>
                Thank You
            </div>
            <div className='order-details'>
                <div className='order'>
                    Thanks for placing order {orderCreationId}
                </div>
                <div className='notify'>
                    We will send you a notification within 24 hours once your order ships.
                </div>
            </div>
            <div className='query'>
                If you have any questions or queries, <br/>feel free to get in touch.
            </div>
            <div className="social-icons thanks-icons">
                <a href="https://www.instagram.com/enslavetothisjournal?igsh=MWFmenNqOGNjOGxvbQ==" target='_blank'>
                <img src={insta} alt="Instagram" />
                </a>
                <a target="_blank" rel="nofollow" href={`mailto:{mailto:hi@enslavetothisjournal.com}`}>
                <img src={thread} alt="Email" />
                </a>
            </div>
            <button className='btn-style add-cart back-home' onClick={backToHome}>Back to Home</button>
            <img className='bulb' src={bulb} />
        </div>
    )
}

export default Thankyou;