import { useEffect } from 'react';
import gsap from 'gsap';
import './title.css';
import group from '../assets/images/Group.svg';
import vector from '../assets/images/Vector.svg';
import book from '../assets/images/sticker-book.svg';
import bookvector from '../assets/images/sticker-book-vector.svg';
import sub from '../assets/images/sticker-sub.svg';
import subVector from '../assets/images/sticker-star.svg';

function Title() {
    useEffect(() => {
        // Select all elements with the class 'vector'
        const vectors = document.querySelectorAll('.vector');

        // Create the animation
        const animation = gsap.to(vectors, {
            rotation: 20, // Tilt 10 degrees to the right
            repeat: -1, // Repeat indefinitely
            yoyo: true, // Animate back to the original position
            ease: "power1.inOut", // Smooth easing
            duration: 1 // Duration of one full cycle
        });

        // Cleanup function to stop the animation when the component unmounts
        return () => {
            animation.kill(); // Stop the animation
        };
    }, []); // Empty dependency array ensures this runs once

    return (
        <div className='title-container'>
            <div className='title-header'>Our Journal is</div>
            <div className='title-images'>
                <div className='sticker'>
                    <div className='sticket-wrapper'>
                        <img src={book} className='stickerr book-assistant' alt="Group"/>
                        <img src={bookvector} className='vector vector1' alt="Vector" />
                    </div>
                    <span className='sticker-name'>
                        <span>Proof read</span>
                        <span>by Therapists</span>
                    </span>
                </div>
                <div className='sticker'>
                    <div className='sticket-wrapper'>
                        <img src={subVector} className='vector vector2' alt="Vector" />
                        <img src={sub} className='stickerr sub' alt="Group"/>
                    </div>
                    <span className='sticker-name'>
                        <span>Sparks</span>
                        <span>Joy</span>
                    </span>
                </div>
                <div className='sticker'>
                    <div className='sticket-wrapper'>
                        <img src={vector} className='vector vector3' alt="Vector" />
                        <img src={group} className='stickerr group-assistant' alt="Group"/>
                    </div>
                    <span className='sticker-name'>
                        <span>Cures</span>
                        <span>doomscrolling</span>
                    </span>
                </div>
            </div>
        </div>
    );
}

export default Title;
