// Cart.js
import './cart.css';
import { gsap } from "gsap";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import journal from '../assets/images/journal.png';
import del from '../assets/images/delete.png';
import Counter from '../counter/Counter';
import Checkout from '../checkout/Checkout';
import { useNavigate } from 'react-router-dom';
import EmptyCart from '../empty-cart/empty-cart';

function Cart({ counterValue, onCountChange }) {
    const navigate = useNavigate();
    const [counter, setCounter] = useState(counterValue || 0);

    const container = useRef(null);

    useEffect(() => {
        window.scroll(0,0);
    }, []);

    useLayoutEffect(() => {
        gsap.fromTo('.cart-container', { y: '-100%', opacity: 1 }, 
            {
            duration: 0.4,
            y: '0%', opacity: 1,
            ease: 'power1.out' 
        });
    }, []);

    const handleCloseClick = () => {
        gsap.to('.cart-container', {
            duration: 0.2,
            y: '-100%',
            onComplete: () => {
                navigate('/')
            }
        });
    };

    const handleCountChange = (newCount) => {
        setCounter(newCount);
        onCountChange(newCount); // Pass the updated counter value to the parent component
    };

    const resetCounter = () => {
        setCounter(0);
    }

    const proceedCheckout = () => {
        navigate('/checkout');
    }

    const openShipping = () => {
        navigate('/shipping-policy');
    }

    const unitPrice = 1500;
    const totalPrice = Number(counter * unitPrice);

    return (
        <div className="cart-container" ref={container}>
            <div className='cart-header'>
                <div></div>
                <div className='cart-header'>
                    Shopping Cart
                </div>
                <button className="close calculation" onClick={handleCloseClick}>&#10005;</button>
            </div>

            {counterValue >= 1 && <div className='cart-content'>
                <div className='journal-details'>
                    <div className='journal-wrapper'>
                        <span className='journal-text'>Enslaved to this journal</span>
                        <img className='journal-image' src={journal} alt="Journal"/>
                    </div>
                </div>
                <div className='journal-pricing'>
                    <div className='quantity'>
                        <div className='wrapper price'>
                            <span className='label'>
                                Price
                            </span>
                            <span className='value main-price'>
                                Rs. {unitPrice}.00
                            </span>
                        </div>
                        <div className='wrapper'>
                            <span className='label'>
                                Quantity
                            </span>
                            <span className='value'>
                                <Counter darkTheme={true} counterValue={counter} onCountChange={handleCountChange} />
                            </span>
                        </div>
                        <div className='wrapper'>
                            <img src={del} className='delete' alt="Delete" onClick={resetCounter}/>
                        </div>
                    </div>
                    <div className='total-calculation'>
                        <div className='row'>
                            <span className='name'>Total</span>
                            <span className='detail'>Rs. {totalPrice}.00</span>
                        </div>
                        <div className='row'>
                            <span className='name subtotal'>Subtotal</span>
                            <span className='detail'>Rs. {totalPrice}.00</span>
                        </div>
                        {/* <div className='row tax-info'>
                            Tax included.&nbsp;<span className='shipping' onClick={openShipping}>Shipping</span>&nbsp;calculated at checkout 
                        </div> */}
                        <div className='checkout-btn'>
                            <Checkout processCheckout={proceedCheckout}/>
                        </div>
                    </div>
                </div>
            </div>}

            {counterValue === 0 && 
            <div className='empty-cart'>
                <EmptyCart/>
            </div>
            }

        </div>
    );
}

export default Cart;
