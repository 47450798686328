import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import Counter from '../counter/Counter';
import './Landing.css';
import banner1 from '../assets/images/1.png';
import banner2 from '../assets/images/2.png';
import banner3 from '../assets/images/3.png';
import arrow from '../assets/images/right-arrow.png';
import { gsap, Sine } from "gsap/all";
import Cloud from '../assets/images/cloud.png';
import heart from '../assets/images/heart.png';
import orange from '../assets/images/orange_bubble.png';
import pencil from '../assets/images/pencil.png';
import camera from '../assets/images/camera.png';
import limited from '../assets/images/Last few copy sticker.svg';
import { useNavigate } from 'react-router-dom';

function Landing({ onCounterChange, initialCounterValue }) {
  const navigate = useNavigate();

  const container = useRef(null);
  const book1Ref = useRef(null);
  const book2Ref = useRef(null);
  const book3Ref = useRef(null);
  const bookContainerRef = useRef(null);
  const limitedRef = useRef(null);
  const isFirstRender = useRef(true);

  const [activeIndex, setActiveIndex] = useState(0);
  const [counterValue, setCounterValue] = useState(initialCounterValue || 0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);

  const [touchStartX, setTouchStartX] = useState(0);
  const [touchEndX, setTouchEndX] = useState(0);

  const minSwipeDistance = 50; // Minimum distance for a swipe to be considered

  const handleTouchStart = (e) => {
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > minSwipeDistance) {
      showNext();
    } else if (touchEndX - touchStartX > minSwipeDistance) {
      showPrev();
    }
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const books = [book1Ref, book2Ref, book3Ref];

  const random = (min, max) => {
    const delta = max - min;
    return (direction = 1) => (min + delta * Math.random()) * direction;
  };
  

  const randomX = random(10, 20);
  const randomY = random(20, 60);
  const randomAngle = random(8, 12);

  useLayoutEffect(() => {
    if (isFirstRender.current) {
      setCounterValue(1);
      isFirstRender.current = false;
    } else {
      setCounterValue(initialCounterValue);
    }
  }, [initialCounterValue]);

  function rotate(target, direction) {
    if (!target) return; // Check if the target is valid
    gsap.to(target, {
      duration: 4,
      rotation: randomAngle(direction),
      ease: Sine.inOut,
      onComplete: rotate,
      onCompleteParams: [target, direction * -1]
    });
  }
  
  function move(target, direction) {
    if (!target) return; // Check if the target is valid
    gsap.to(target, {
      duration: 2,
      x: randomX(direction),
      y: randomY(direction),
      ease: Sine.inOut,
      onComplete: move,
      onCompleteParams: [target, direction * -1]
    });
  }

  function onAddToCart() {
    onCounterChange(counterValue);
    navigate('/cart')
  }

  useEffect(() => {
    setTimeout(() => {
      gsap.context(() => {
        gsap.set(books.slice(1).map(book => book.current), { xPercent: 100, opacity: 0, display: 'none' });
        gsap.fromTo(books[0].current, { xPercent: 100, opacity: 0 }, { xPercent: 0, opacity: 1, duration: 1 });
      }, container);
    }, 2000)
  }, [bookContainerRef])
  // In the useEffect for initializing animations
  useEffect(() => {
    gsap.set('.cloud', {
      x: randomX(-1),
      y: randomY(1),
      rotation: randomAngle(-1)
    });

    function animateCloud() {
      gsap.to('.cloud', {
        y: random(-30, 30), // Moves the spaceship up and down randomly
        x: random(-10, 10),
        duration: 6,
        repeat: -1,
        yoyo: true, // Makes the animation go back and forth
        ease: 'power1.inOut',
        onComplete: animateCloud
      });
    }

    animateCloud();

    // Pencil tilting animation
    function tiltPencil() {
      gsap.to('.pencil', {
        duration: 1.5,
        rotation: 20,
        ease: Sine.inOut,
        yoyo: true,
        repeat: -1
      });
    }

    tiltPencil();

    function tiltCamera() {
      gsap.to('.camera', {
        duration: 1.5,
        rotation: 20,
        ease: Sine.inOut,
        yoyo: true,
        repeat: -1
      });
    }

    tiltCamera();

    // Limited image rotating animation
    function rotateLimited() {
      gsap.to(limitedRef.current, {
        duration: 8,
        rotation: 360,
        ease: 'none',
        repeat: -1
      });
    }

    rotateLimited();

    return () => {
      gsap.killTweensOf('.cloud');
      gsap.killTweensOf('.pencil');
      gsap.killTweensOf('.camera');
      gsap.killTweensOf(limitedRef.current);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 900);
    };

    const debounceResize = debounce(handleResize, 200);

    window.addEventListener('resize', debounceResize);

    return () => {
      window.removeEventListener('resize', debounceResize);
    };
  }, []);

  useEffect(() => {
    setCounterValue(initialCounterValue);
  }, [initialCounterValue]);

  const showNext = () => {
    const currentIndex = activeIndex;
    const nextIndex = (currentIndex + 1) % books.length;

    gsap.timeline()
      .to(books[currentIndex].current, { xPercent: -100, opacity: 0, duration: 0.5, display: 'none' })
      .fromTo(books[nextIndex].current, { xPercent: 100, opacity: 0, display: 'block' }, { xPercent: 0, opacity: 1, duration: 0.5 });

    setActiveIndex(nextIndex);
  };

  const showPrev = () => {
    const currentIndex = activeIndex;
    const prevIndex = (currentIndex - 1 + books.length) % books.length;

    gsap.timeline()
      .to(books[currentIndex].current, { xPercent: 100, opacity: 0, duration: 0.5, display: 'none' })
      .fromTo(books[prevIndex].current, { xPercent: -100, opacity: 0 }, { xPercent: 0, opacity: 1, duration: 0.5, display: 'block' });

    setActiveIndex(prevIndex);
  };

  const handleCountChange = (newCount) => {
    setCounterValue(newCount);
  };

  return (
    <div className="landing-section" ref={container}>
      <div className="left-panel">
        <div className="main-text hero-text">Create Stories</div>
        <div className='sub-text'>
          Fill this journal with joy and craft your stories; we challenge you to push boundaries and embrace your true self. Test yourself with every page, crafted by humans for humans.
        </div>
        {!isSmallScreen && (
          <div className='action-btns'>
            <div className='price-section'>
              <span className='price'>&#8377;1500</span>
              <span className='og-price'>&#8377;2000</span>
              <span className='taxes'>Incl. of all taxes</span>
            </div>
            <div className='add-cart-wrapper'>
              <div>
                <Counter className="main-counter" onCountChange={handleCountChange} counterValue={counterValue}/>
                <button className='btn-style add-cart' onClick={onAddToCart}>Add to cart</button>
              </div>
              <img className='limited' src={limited} alt="Limited" ref={limitedRef} />
            </div>
          </div>
        )}
      </div>
      <div className="right-panel">
        <div className='book-container'
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              ref={bookContainerRef}>
          <div className='img-wrapper wrapper1' ref={book1Ref}>
            <img id="heart" src={heart} alt="Heart" />
            <img src={banner1} className='book book1' alt="Book 1" />
            <img src={Cloud} className='cloud' alt="Cloud" />
            <img src={orange} className='orange' alt="Orange Bubble" />
          </div>
          <div className='img-wrapper wrapper2' ref={book2Ref}>
            <img src={orange} className='orange2' alt="Orange Bubble" />
            <img src={banner2} className='book' alt="Book 2" />
            <img src={pencil} className='pencil' alt="Pencil" />
          </div>
          <div className='img-wrapper wrapper3' ref={book3Ref}>
            <img src={banner3} className='book' alt="Book 3" />
            <img src={orange} className='orange3' alt="Orange Bubble" />
            <img src={camera} className='camera' alt="Camera" />
          </div>
        </div>
        <div className='arrows'>
          <span onClick={showPrev} className='arrow'><img className="arrow1" src={arrow} alt="Previous" /></span>
          <span onClick={showNext} className='arrow'><img className="arrow2" src={arrow} alt="Next" /></span>
        </div>
      </div>
      {isSmallScreen && (
        <div className='action-btns'>
          <div className='price-section'>
            <span className='price'>&#8377;1500</span>
            <span className='og-price'>&#8377;2500</span>
            <span className='taxes'>Incl. of all taxes</span>
          </div>
          <div className='add-cart-wrapper'>
            <div>
              <Counter className="main-counter" onCountChange={handleCountChange} counterValue={counterValue}/>
              <button className='btn-style add-cart' onClick={onAddToCart}>Add to cart</button>
            </div>
            <img className='limited' src={limited} alt="Limited" ref={limitedRef} />
          </div>
        </div>
      )}
    </div>
  );
}

function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
}

export default Landing;
