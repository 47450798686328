import './empty-cart.css';
import emptyCart from '../assets/images/Empty cart.svg';
import { useNavigate } from 'react-router-dom';

function EmptyCart() {
    const navigate = useNavigate()

    const navigateHome = () => {
        navigate('/');
    }
    return (
        <div className='empty-container'>
            <img className='empty-image' src={emptyCart} />
            <button className="continue-button" onClick={navigateHome}>Continue Shopping</button>
        </div>
    )
}

export default EmptyCart;