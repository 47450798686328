import './header.css';
import cart from '../assets/images/Shopping cart.svg';
import logo from '../assets/images/Logo.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import home from '../assets/images/Home.svg';

function Header({ counterValue }) {
    const navigate = useNavigate();
    const [cartValue, setCartValue] = useState(counterValue || 0);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        setCartValue(counterValue || 0);
    }, [counterValue]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const openCart = () => {
        navigate('/cart');
    };

    const navigateHome = () => {
        navigate('/');
    };

    return (
        <div className={`header ${scrolled ? 'scrolled' : ''}`}>
            <img className='logo' src={logo} alt="Logo" />
            <span className="home" onClick={navigateHome}><img src={home} alt="Home" /></span>
            <span 
                className={`cart ${cartValue > 0 ? 'show-counter' : ''}`} 
                onClick={openCart} 
                data-value={cartValue}
            >
                <img className='cart-img' src={cart} alt="Cart" />
            </span>
        </div>
    );
}

export default Header;
