import React, { useState } from 'react';
import './Coupon.css';
import Coupon from '../../assets/images/Coupon.svg';
import Close from '../../assets/images/Close.svg'; 

const CouponComponent = ({ onApplyCoupon }) => {
  const [couponCode, setCouponCode] = useState('');
  const [matchedCoupon, setMatchedCoupon] = useState('');
  const [error, setError] = useState('');

  const coupons = [
    { code: 'GAREEB', discount: 'Flat 50% Off', value: 750 },
    { code: 'EARLY200', discount: '200 OFF', value: 200 },
    { code: 'IDIOT250', discount: '250 OFF', value: 250 },
    { code: 'HARINDEROP', discount: '250 OFF', value: 250 },
    { code: 'EARLY500', discount: '500 OFF', value: 500 },
  ];

  const resetCoupon = () => {
    setCouponCode('');
    setMatchedCoupon('');
    onApplyCoupon(0);
  }

  const handleApplyCoupon = () => {
    const matchedCoupon = coupons.find(coupon => coupon.code === couponCode.toUpperCase());
    
    if (matchedCoupon) {
      setMatchedCoupon(matchedCoupon);
      setError('');
      onApplyCoupon(matchedCoupon.value);
    } else {
      setError('Invalid coupon code');
    }
  };

  return (
    <div className='main-coupon'>
        {!matchedCoupon && <div className='coupon-container'>
            <input 
                className='coupon-input' 
                type="text" 
                value={couponCode} 
                onChange={(e) => setCouponCode(e.target.value)} 
                placeholder="Enter coupon code"
            />
            <button className='coupon-style' onClick={handleApplyCoupon}>Apply</button>
        </div>}

        {matchedCoupon && 
        <div className='applied-coupon'>
            <img src={Coupon}/>
            <span className='coupon-name'>{matchedCoupon.code}</span>
            <img className='resetCoupon' src={Close} onClick={resetCoupon}/>
        </div>}
      {error && <p className='invalid-coupon' style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default CouponComponent;
