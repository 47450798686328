import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import './why.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { gsap } from 'gsap';

import discoverFront from '../assets/images/Discover yourself front.svg';
import discoverBack from '../assets/images/Discover yourself back.svg';
import experimentFront from '../assets/images/Experiential tasks front.svg';
import experimentBack from '../assets/images/Experiential Tasks back.svg';
import funFront from '../assets/images/Fun prompts Front.svg';
import funBack from '../assets/images/Fun Promt Back.svg';
import habitBack from '../assets/images/Habit Building Nudges back.svg';
import habitFront from '../assets/images/Habit Building Nudges front.svg';
import milestoneBack from '../assets/images/Milestone Frame Back.svg';
import milestoneFront from '../assets/images/Milstone Tracker Front.svg';
import arrow from '../assets/images/right-arrow.png';
import underline from '../assets/images/Underline.svg';

function Why() {
  const carouselRef = useRef(null);
  const [autoPlay, setAutoPlay] = useState(true);

  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleMouseEnter = () => {
    setAutoPlay(false);
    setTimeout(() => {
      setAutoPlay(true);
    }, 10000);
  };

  const handleMouseLeave = () => {
    setAutoPlay(true);
  };

  const isMobile = () => {
    return window.innerWidth <= 1024; // Adjust this breakpoint as needed
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      const ctx = gsap.context(() => {
        const cards = gsap.utils.toArray('.cardCont');
        cards.forEach(card => {
          gsap.set(card, {
            transformStyle: 'preserve-3d',
            transformPerspective: 1000,
          });

          const front = card.querySelector('.cardFront');
          const back = card.querySelector('.cardBack');

          gsap.set(back, { rotationY: -180 });

          const tl = gsap.timeline({ paused: true }).invalidate();
          const progress = tl.progress();
          const reversed = tl.reversed();

          tl.progress(0).clear();

          tl
            .to(front, { duration: 0.6, rotationY: 180, ease: 'power2.inOut' })
            .to(back, { duration: 0.6, rotationY: 0, ease: 'power2.inOut' }, 0)
            .to(card, { z: 50, ease: 'power2.inOut' }, 0)
            .to(card, { z: 0, ease: 'power2.inOut' }, 0.3)
            .progress(progress)
            .reversed(reversed);

          if (isMobile()) {
            setAutoPlay(false);
            const observer = new IntersectionObserver(
              entries => {
                entries.forEach(entry => {
                  if (entry.isIntersecting) {
                    setAutoPlay(false);
                    tl.play();
                    setTimeout(() => {
                      setAutoPlay(true);
                    }, 5000)
                  } else {
                    tl.reverse();
                  }
                });
              },
              { threshold: 0.5 } // Adjust the threshold as needed
            );

            observer.observe(card);
          } else {
            card.addEventListener('mouseenter', () => {
              handleMouseEnter();
              if (tl.paused() || tl.reversed()) {
                tl.play();
              } else {
                tl.reverse();
              }
            });

            card.addEventListener('mouseleave', () => {
              handleMouseLeave();
              if (tl.paused() || tl.reversed()) {
                tl.play();
              } else {
                tl.reverse();
              }
            });
          }

          // Touch events for mobile devices
          card.addEventListener('touchstart', handleMouseEnter);
          card.addEventListener('touchend', handleMouseLeave);
        });
      });
      return () => ctx.revert();
    });
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // Sliding one item at a time for smoothness
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1, // Sliding one item at a time for smoothness
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // Sliding one item at a time for smoothness
    },
  };

  return (
    <div className='why-container'>
      <div className='why-header'>
        <span className='why-txt'>WHY US</span>
        <img src={underline} alt="underline" />
      </div>
      <Carousel
        ref={carouselRef}
        swipeable={true}
        draggable={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={autoPlay}
        autoPlaySpeed={2000} // Adjusted for smoother auto play
        keyBoardControl={true}
        customTransition="transform 0.5s ease-in-out" // Smooth transition effect
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={['tablet', 'mobile']}
        itemClass="carousel-item-padding-40-px"
      >
        <div className='cardCont'>
          <div className='cardBack'>
            <img src={discoverBack} alt='Discover yourself back' />
          </div>
          <div className='cardFront'>
            <img src={discoverFront} alt='Discover yourself front' />
          </div>
        </div>
        <div className='cardCont'>
          <div className='cardBack'>
            <img src={experimentBack} alt='Experiential tasks back' />
          </div>
          <div className='cardFront'>
            <img src={experimentFront} alt='Experiential tasks front' />
          </div>
        </div>
        <div className='cardCont'>
          <div className='cardBack'>
            <img src={funBack} alt='Fun prompts back' />
          </div>
          <div className='cardFront'>
            <img src={funFront} alt='Fun prompts front' />
          </div>
        </div>
        <div className='cardCont'>
          <div className='cardBack'>
            <img src={habitBack} alt='Habit Back' />
          </div>
          <div className='cardFront'>
            <img src={habitFront} alt='Habit Front' />
          </div>
        </div>
        <div className='cardCont'>
          <div className='cardBack'>
            <img src={milestoneBack} alt='Milestone back' />
          </div>
          <div className='cardFront'>
            <img src={milestoneFront} alt='Milestone front' />
          </div>
        </div>
      </Carousel>

      <div className='slider-container'>
        <span onClick={handleNext} className='custom-arrow right-arrow'>
          <img className='arrow2' src={arrow} alt='arrow1' />
        </span>
        <span onClick={handlePrev} className='custom-arrow left-arrow'>
          <img className='arrow1' src={arrow} alt='arrow1' />
        </span>
      </div>
    </div>
  );
}

export default Why;
