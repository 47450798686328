import React, { useState } from 'react';
import './input.css';

function Input({ onSendEmail, error, clearError }) {
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(true);

    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        setIsValid(true); // Reset the validation state on input change

        // Clear the error message when user starts typing
        if (error) {
            clearError(); // Call the clearError function from the parent
        }
    };

    const handleSend = () => {
        const valid = validateEmail(email);
        setIsValid(valid);
        if (valid && email) {
            onSendEmail(email);
        }
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(String(email).toLowerCase());
    };

    return (
        <div className="input-container">
            <div className='error-wrapper'>                
                <input
                    type="email"
                    placeholder="Email"
                    className={`input ${isValid ? '' : 'invalid'}`}
                    value={email}
                    onChange={handleChange}
                />
                <button className="send-style" onClick={handleSend}>Send</button>
            </div>
            {!isValid && <span className="error">Please enter a valid email address</span>}
            {error && <span className="error">{error}</span>}
        </div>
    );
}

export default Input;
