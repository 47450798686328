// axiosInstance.js
import axios from 'axios';
import { useLoader } from './loader-context';

const useAxiosInstance = () => {
    const { setApiLoader } = useLoader();

    const axiosInstance = axios.create({
    });

    axiosInstance.interceptors.request.use(
        (config) => {
            setApiLoader(true);
            return config;
        },
        (error) => {
            setApiLoader(false);
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            setApiLoader(false);
            return response;
        },
        (error) => {
            setApiLoader(false);
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};

export default useAxiosInstance;
