import './saved-address.css';
import edit from '../../assets/images/edit.svg'

const SavedForm = ({ formData, onEdit }) => {
    const handleEditClick = () => {
        onEdit(formData); // Call the onEdit handler with the current form data
    };
    return (
        <div className='saved-address'>
            <div className='saved-heading'>Saved Address</div>
            <div className='saved-content'>
                <div className='saved-content-header'>
                    <span className='saved-title'>Enslaved Journal</span>
                    <img src={edit} onClick={handleEditClick}/>
                </div>
                <div className='saved-addr'>
                    {formData.apartment}, {formData.address},<br/> {formData.city},{formData.state}, {formData.country}, {formData.pincode}
                </div>
            </div>
        </div>
    )
}

export default SavedForm;