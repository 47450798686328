import './shipping.css';
import arrow from '../assets/images/right-arrow.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import rocket from '../assets/images/Ship.svg';

function Shipping() {
    const navigate = useNavigate();
    
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    const onCloseShipping = () => {
        navigate('/');
    }

    return (
        <div className="shipping-container">
            <div className='terms-header'>
                <span className='go_back' onClick={onCloseShipping}><img className="arrow2 back_option" src={arrow} alt="arrow1" /></span>
                <span className='shipping-policy-text'>Shipping Policy</span>
                <span></span>
            </div>

            <div className='shipping-content'>
                <img className='rocket' src={rocket}/>
                            <p className='shipping_text'><span>Processing Time</span> &nbsp;Orders are processed within 1-2 business day.</p>
                            <p className='shipping_text'><span>Shipping Method</span> &nbsp;We offer Standard shipping (5-7 business days).</p>
                            <p className='shipping_text'><span>Shipping Cost</span> &nbsp;Shipping is free for all orders.</p>
                            <p className='shipping_text'><span>Tracking</span> &nbsp;A tracking number will be provided via email once your order ships.</p>
                            <p className='shipping_text'><span>Domestic Shipping</span> &nbsp;Only We currently do not offer international shipping.</p>
                            <p className='shipping_text'><span>Damages</span> &nbsp;Report any damaged items within 48 hours of receiving your order.</p>
                            <p className='shipping_text'>For questions, contact: hi@enslavetothisjournal.com</p>

            </div>
            
        </div>
    )
}

export default Shipping;