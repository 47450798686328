import React, { useEffect, useRef } from 'react';
import './footer.css';
import { gsap } from 'gsap';
import logo from '../assets/images/Logo.svg';
import insta from '../assets/images/Instagram.svg';
import email from '../assets/images/Email-contact.svg';
import spaceship from '../assets/images/Spaceship.svg';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();
  const spaceshipRef = useRef(null);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    // Different y-axis animation values for mobile and desktop
    const yValue = isMobile ? 'random(-30, 30)' : 'random(-20, 20)';

    // Random float animation on y-axis only
    gsap.to(spaceshipRef.current, {
      y: yValue, // Moves the spaceship up and down randomly
      duration: 3,
      repeat: -1,
      yoyo: true, // Makes the animation go back and forth
      ease: 'power1.inOut'
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onShowPrivacy = () => {
    navigate('/privacy-policy');
  };

  const onShowTerms = () => {
    navigate('/terms-and-conditions');
  };

  const onShowReturn = () => {
    navigate('/return-policy');
  };

  const onShowShipping = () => {
    navigate('/shipping-policy');
  };

  return (
    <footer className="footer">
      <img className="spaceship" ref={spaceshipRef} src={spaceship} alt="Spaceship" />
      <div className="footer-content">
        <div className="footer-section">
          <a href="/">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className="footer-section">
          <p className="upper-footer made">
            Made with <span className="small-heart" role="img" aria-label="heart">❤️</span> by Stroffe
          </p>
          <p className="upper-footer">Contact Us</p>
          <div className="social-icons">
            <a href="https://www.instagram.com/enslavetothisjournal?igsh=MWFmenNqOGNjOGxvbQ==" target='_blank'>
              <img src={insta} alt="Instagram" />
            </a>
            <a target="_blank" rel="nofollow" href={`mailto:{mailto:hi@enslavetothisjournal.com}`}>
              <img src={email} alt="Email" />
            </a>
          </div>
        </div>
        <div className="footer-section">
          <p className="policies">
            <span onClick={onShowPrivacy}>Privacy Policy</span> | 
            <span onClick={onShowTerms}>Terms of Use</span> | 
            <span onClick={onShowReturn}>Return Policy</span> |
            <span onClick={onShowShipping}>Shipping Policy</span>
          </p>
          <p className="policies">© 2024 Stroffe. All rights reserved.</p>
        </div>
      </div>
      <button className="btn-style add-cart back-to-top" onClick={scrollToTop}>Back to top</button>
    </footer>
  );
};

export default Footer;
