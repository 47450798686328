import { useEffect, useState } from "react";
import './counter.css';

function Counter({darkTheme, onCountChange, counterValue }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(counterValue || 0);
    onCountChange(counterValue);
  }, [counterValue]);

  const increment = () => {
    const newCount = count + 1;
    setCount(newCount);
    onCountChange(newCount);
  };

  const decrement = () => {
    const newCount = count - 1 > 0 ? count - 1 : 0;
    setCount(newCount);
    onCountChange(newCount);
  };

    return (
        <div className="Counter">
            <button className={`btn-style calculation ${darkTheme ? 'dark' : 'light'}`} onClick={decrement}>-</button>
            <h1 className={`btn-style count ${darkTheme ? 'dark' : 'light'}`}>{count}</h1>
            <button className={`btn-style calculation ${darkTheme ? 'dark' : 'light'}`} onClick={increment}>+</button>
        </div>
    );
}

export default Counter;
