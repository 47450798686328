import './testimonials.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import heart from '../assets/images/Heart Vector.svg';
import maleblack from '../assets/images/Male Black (1).svg';
import femaleBlack from '../assets/images/Female Beige-1.svg';
import femaleBlack2 from '../assets/images/Female 3.svg';
import yay from '../assets/images/Yay Vector.svg';
import highlight from '../assets/images/Name Highlight Orange vector.svg';
import { useRef, useEffect } from 'react';
import arrow from '../assets/images/right-arrow.png';

function Testimonials() {
  const testimonialCarouselRef = useRef(null);
  const profileCarouselRef = useRef(null);

  // Synchronize the profile carousel to match the testimonial carousel
  const syncProfileCarousel = (nextIndex) => {
    if(nextIndex > 4) {
      nextIndex = 2;
    }
  
    if (profileCarouselRef.current) {
      const items = document.querySelectorAll('.profile-container .react-multi-carousel-item');
      
      // Remove the class before the transition starts
      
      // Use a timeout to align with the start of the transition
      setTimeout(() => {
        items.forEach(item => item.classList.remove('centered'));
        items[nextIndex + 2]?.classList.add('centered');
      }, 100); // Or adjust this delay to match your transition timing
    }
  };
  
  syncProfileCarousel(2);

  const handleAfterChange = (previousSlide, { currentSlide }) => {
    syncProfileCarousel(currentSlide);
  };

  const handleNext = () => {
    if (testimonialCarouselRef.current && profileCarouselRef.current) {
      const currentSlide = testimonialCarouselRef.current.state.currentSlide;
      testimonialCarouselRef.current.next();
      profileCarouselRef.current.next();
      syncProfileCarousel(currentSlide + 1);
    }
  };

  const handlePrev = () => {
    if (testimonialCarouselRef.current && profileCarouselRef.current) {
      const currentSlide = testimonialCarouselRef.current.state.currentSlide;
      testimonialCarouselRef.current.previous();
      profileCarouselRef.current.previous();
      syncProfileCarousel(currentSlide - 1);
    }
  };


  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const profileResponsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 3 },
  };

  return (
    <div className='testimonial-container'>
      <Carousel
        ref={profileCarouselRef}
        swipeable={true}
        draggable={true}
        responsive={profileResponsive}
        ssr={true}
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
        customTransition="transform 0.2s ease-in-out"
        transitionDuration={500}
        containerClass="profile-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="profile-item-padding-40-px"
      >
        <div className='profiles'>
          <img className='profile female' src={femaleBlack} alt="Profile 2" />
        </div>
        <div className='profiles'>
          <img className='profile female2' src={femaleBlack2} alt="Profile 3" />
        </div>
        <div className='profiles'>
          <img className='profile male' src={maleblack} alt="Profile 1" />
        </div>
      </Carousel>

      <div className='testimonials'>
        <img className='test-heart' src={heart} alt="Heart" />
        <img className='test-yay' src={yay} alt="Yay" />

        <Carousel
          ref={testimonialCarouselRef}
          swipeable={true}
          draggable={true}
          responsive={responsive}
          ssr={true}
          infinite={true}
          autoPlay={false}
          keyBoardControl={true}
          customTransition="transform 0.5s ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          itemClass="carousel-item-padding-40-px"
          afterChange={handleAfterChange} // Hook into afterChange to sync profile carousel
        >
           <div className='test-content'>
                        <div className='test-txt'>
                        I really liked this journal because it helped me think about things that matter to me, like my favorite memories and what I want to do in the future. The prompts made it easy to explore my thoughts and feelings. It's like having a friend to talk to through drawings and writing
                        </div>
                        <div className='author'>
                            Anmol, Counselling Psychologist <img src={highlight} alt="Highlight" />
                        </div>
                    </div>
                    <div className='test-content'>
                        <div className='test-txt'>
                        This journal is like a handy tool for exploring feelings and ideas. The prompts are fun and thoughtful, helping you learn more about yourself in a fun way. It's great for anyone who wants to understand themselves better
                        </div>
                        <div className='author'>
                        Prajjwal, Certified Introvert <img src={highlight} alt="Highlight" />
                        </div>
                    </div>
                    <div className='test-content'>
                        <div className='test-txt'>
                        I had a lot of fun with this journal! The activities are really creative and got me thinking about all sorts of cool things, like creating new holidays and drawing my dream job. It's perfect for hanging out with friends and sharing ideas. Definitely a fun way to show what you're all about!
                        </div>
                        <div className='author'>
                        Priyanshi, Certified Extrovert <img src={highlight} alt="Highlight" />
                        </div>
                    </div>
        </Carousel>
      </div>

      <div className='slider-container'>
        <span onClick={handleNext} className='custom-arrow right-arrow'>
          <img className="arrow2" src={arrow} alt="arrow2" />
        </span>
        <span onClick={handlePrev} className='custom-arrow left-arrow'>
          <img className="arrow1" src={arrow} alt="arrow1" />
        </span>
      </div>
    </div>
  );
}

export default Testimonials;