import './privacy.css';
import arrow from '../assets/images/right-arrow.png';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import lock from '../assets/images/Lock.svg';

function Privacy() {
    const navigate = useNavigate();
    
    useEffect(() => {
        window.scroll(0,0)
    }, [])

    const onClosePrivacy = () => {
        navigate('/');
    }

    return (
        <div className="privacy-container">
            <div className='terms-header'>
                <span className='go_back' onClick={onClosePrivacy}><img className="arrow2 back_option" src={arrow} alt="arrow1" /></span>
                <span className='privacy-policy-text'>Privacy Policy</span>
                <span></span>
            </div>

            <div className='privacy-content'>
                <img className='lock' src={lock}/>
                <h2>WHAT DO WE DO WITH YOUR INFORMATION?</h2>
                            <p className='privacy_text'>At our online store, we take your privacy seriously. We will only use your personal information to send you updates on your orders or to notify you when we have something exciting to share.</p>
                            <p className='privacy_text'>We will never share or sell your information to third parties. We are committed to keeping your information safe and secure.</p>
                            <p className='privacy_text'>If you have any concerns about your privacy or data security, please feel free to contact us at hi@enslavetothisjournal.com</p>
            </div>
        </div>
    )
}

export default Privacy;