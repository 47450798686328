import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import { gsap, ScrollTrigger } from "gsap/all";
import Landing from './landing-view/Landing';
import Header from './header/Header';
import Cart from './cart/Cart';
import Thankyou from './thankyou/Thankyou';
import underline from './assets/images/Underline.svg';
import Title from './title/Title';
import Why from './why-us/Why';
import Testimonials from './testimonials/Testimonials';
import Newsletter from './newsletter/newsletter';
import Footer from './footer/footer';
import Terms from './terms-and-conditions/terms';
import Privacy from './privacy/privacy';
import Return from './return-poilicy/return';
import Shipping from './shipping/shipping';
import AddressForm from './address-form/address-form';
import Loader from './loader/loader';
import ApiLoader from './loader/api-loader';
import CartWidget from './cart-widget/cart-widget';
import { LoaderProvider } from './loader-context';
import NotFound from './notfound/NotFound';

gsap.registerPlugin(ScrollTrigger);

function App() {
  const [loading, setLoading] = useState(true);
  const [orderCreationId, setOrderCreationId] = useState(null);
  const [counterValue, setCounterValue] = useState(0);
  const [cartVisible, setCartVisible] = useState(false); // Initially set to true to make cart widget visible

  const floatingWidgetRef = useRef(null);
  const sideButtonRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);


  // useLayoutEffect(() => {
  //   if (floatingWidgetRef.current) {
  //     ScrollTrigger.create({
  //       trigger: '.quote',
  //       start: 'top center',
  //       end: 'bottom center',
  //       toggleActions: 'play none none reverse',
  //       onEnter: () => {
  //         if(cartVisible) {
  //           gsap.fromTo(floatingWidgetRef.current, { x: '100%',  autoAlpha: 0 }, { x: 0, duration: 1,  autoAlpha: 1, ease: 'power2.out' });
  //         }
  //       },
  //       onLeaveBack: () => {
  //         if(!cartVisible) {
  //           gsap.to(floatingWidgetRef.current, { x: '100%', duration: 1,  autoAlpha: 0, ease: 'power2.out' });
  //         }
  //       }
  //     });
  //   }
  // }, [cartVisible]);
  

  const handleCounterChange = (newCount) => {
    setCounterValue(newCount);
  };

  const handleWidgetCounter = () => {
    setCounterValue(1);
    hideCart();
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }, 1000)
  }

  const handleOrderCreationId = (id) => {
    setOrderCreationId(id);
  };

  const showCart = () => {
    setCartVisible(true);
    gsap.set(floatingWidgetRef.current, { visibility: 'visible' }); // Ensure it's visible before animating
    gsap.fromTo(
      floatingWidgetRef.current, 
      { x: '100%' }, 
      { x: 0, duration: 1, ease: 'power2.out' }
    );
  };
  
  const hideCart = () => {
    gsap.to(floatingWidgetRef.current, {
      x: '100%', 
      duration: 1, 
      ease: 'power2.out', 
      onComplete: () => {
        gsap.set(floatingWidgetRef.current, { visibility: 'hidden' }); // Hide after animation completes
        setCartVisible(false);
      }
    });
  };

  return (
    <Router>
      <LoaderProvider>
        <ApiLoader />
        <div className="App">
          {loading && 
            <Loader /> 
          }
            <>
              <Header counterValue={counterValue}/>
              <Routes>
                <Route path="/" element={
                  (
                    <div className='landing-wrapper'>
                        <div className='floating-widget' ref={floatingWidgetRef}>
                          <CartWidget onClose={hideCart} counterValue={handleWidgetCounter}/>
                        </div>
                      <Landing onCounterChange={handleCounterChange} initialCounterValue={counterValue}/>
                      <div className='quote'>
                        <span className='text-1'>Write your story, </span>
                        <span className='text-1'>from meh to magnificent</span>
                        <div className='underline'>
                          <img className='underlining' src={underline} alt="underline"/>
                        </div>
                        <div className='side-button' onClick={showCart} style={{ display: cartVisible ? 'none' : 'block' }}>
                          ADD TO CART
                        </div>
                      </div>
                      <Why/>
                      <Testimonials/>
                      <Title/>
                      <Newsletter/>
                      <Footer/>
                    </div>
                  )}
                />
                <Route path="/terms-and-conditions" element={
                  (
                    <div className='terms-conditions'>
                      <Terms/>
                      <Footer/>
                    </div>
                  )}
                />
                <Route path="/privacy-policy" element={
                  (
                    <div className='privacy-policy'>
                      <Privacy/>
                      <Footer/>
                    </div>
                  )}
                />
                <Route path="/return-policy" element={
                  (
                    <div className='return-policy'>
                      <Return/>
                      <Footer/>
                    </div>
                  )}
                />
                <Route path="/shipping-policy" element={
                  (
                    <div className='shipping-policy'>
                      <Shipping/>
                      <Footer/>
                    </div>
                  )}
                />
                <Route path="/thank-you" element={
                  <Thankyou orderCreationId={orderCreationId}/>
                }/>
                <Route path="/cart" element={
                  <Cart counterValue={counterValue} onCountChange={handleCounterChange}/>
                }/>
                <Route path="/checkout" element={
                  <AddressForm onOrderCreationId={handleOrderCreationId} counterValue={counterValue}/>
                }/>
                <Route path="*" element={<NotFound/>} />
              </Routes>
            </>
        </div>
      </LoaderProvider>
    </Router>
  );
}

export default App;
